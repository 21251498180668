// 后端接口都是下划线链接，这里忽略驼峰校验
/* eslint-disable camelcase */
import { getJson, deleteJson, postJSON, postFormData } from 'components/api/request';
import { ApiRequest, ProductsReqBase, ResponseBase, ResponseListBase } from 'components/api/types';
import { TOPIC_TYPE, TOPIC_SCENE, TOPIC_TAB, SHOW_STATUS } from 'components/constants/roadmap';

interface GetApiV3RoadmapSettingDashboardRespData {
  status: SHOW_STATUS
  sort_ids: number[]
  group_sort_ids: number[]
  banners: {
    type: {
      doing: string
      done: string
    }
  }
  stat_keys: string[]
}

interface postApiV3RoadmapSetting extends ProductsReqBase {
  status?: SHOW_STATUS
  sortIds?: number[]
  groupSortIds?: number[]
  banners?: {
    type: {
      doing: string
      done: string
    }
  }
  statKeys?: string[]
}
/**  产品-Roadmap-配置 - 状态获取 */
export const getApiV3RoadmapSettingStatus: ApiRequest<
  ProductsReqBase,
  ResponseBase<{status:SHOW_STATUS}>
> = ({ productId }) => getJson({
  url: `/api/v3/${productId}/roadmap/setting/status`,
});



/**  产品-Roadmap-配置 - 状态获取 */
export const getApiV3RoadmapSettingDashboard: ApiRequest<
  ProductsReqBase,
  ResponseBase<GetApiV3RoadmapSettingDashboardRespData>
> = ({ productId }) => getJson({
  url: `/api/v3/${productId}/roadmap/setting/dashboard`,
});


/**  产品-Roadmap-配置 - 状态获取 */
export const postApiV3RoadmapSetting: ApiRequest<
  postApiV3RoadmapSetting,
  ResponseBase<any>
> = ({ productId, banners, statKeys, status, sortIds, groupSortIds }) => postJSON({
  url: `/api/v3/${productId}/roadmap/setting`,
  data: {
    status,
    sort_ids: sortIds,
    group_sort_ids: groupSortIds,
    banners,
    stat_keys: statKeys,
  },
});
