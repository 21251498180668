import { PLATFORM_ID, PLATFORM_STATUS } from 'components/constants/tapd';

// 检查是否授权过tapd
export function hasTapdPlatform(platforms = []) {
  for (let i = 0; i < platforms.length; i++) {
    if (
      platforms[i].id === PLATFORM_ID.TAPD && platforms[i].status === PLATFORM_STATUS.ON
    ) {
      return true;
    }
  }
  return false;
}
