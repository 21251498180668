import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal } from 'components/modal';
import { Avatar } from 'components/user';
import { METHOD } from 'components/notification/constants';
import WxSubscripeContent from './wx-subscripe-content';
import { EmailSubscripeContent } from './email-subscripe-content';
import './style.less';

export class PostPopupWxSubscripe extends Component {
  render() {
    const { batchReply, avatar, methodPc } = this.props;
    return (
        <Modal onClose={this.props.onClose}>
            <div className={classNames('post_pop_ups', { batchReply })}>
                <div className="banner">
                    <Avatar src={avatar} />
                </div>
                <div className="post-popup-wx-sub post_form">
                    {methodPc === METHOD.wx_subscribe
                    && <WxSubscripeContent {...this.props} />}
                    {methodPc === METHOD.email
                    && <EmailSubscripeContent
                        {...this.props.userData}
                        onClose={this.props.onClose}
                        scene={this.props.scene}
                        handlEmailConfirm={this.props.handlEmailConfirm}
                    />}
                </div>
            </div>
        </Modal>
    );
  }
}

PostPopupWxSubscripe.propTypes = {
  scene: PropTypes.string,
  methodPc: PropTypes.string,
  batchReply: PropTypes.string,
  avatar: PropTypes.string,
  url: PropTypes.string,
  status: PropTypes.string,
  userData: PropTypes.object,
  onClose: PropTypes.func,
  handlEmailConfirm: PropTypes.func,
};
PostPopupWxSubscripe.defaultProps = {
  onClose: () => console.log('onClose 没有'),
  batchReply: false,
};
